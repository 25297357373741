
import { Component, Vue } from "vue-property-decorator"
import {
  ConsultantInvoice,
  FinanceStatisticsQuery,
  FinanceStatisticsQueryQuery,
  MissionLifecycleSelectQuery,
  InvoicesListQuery,
} from "@/gql"
import AppPage from "@/components/layout/AppPage.vue"
import Table from "@/components/widgets/data/Table.vue"
import { FetchResult } from "apollo-link"

@Component({ components: { Table, AppPage } })
export default class Invoices extends Vue {
  statsQuery = FinanceStatisticsQuery
  readonly missionLifecycleSelectQuery = MissionLifecycleSelectQuery

  headers = [
    {
      text: "Consultant",
      value: "consultant",
      custom: true,
      sortable: false,
    },
    {
      text: "Mission",
      value: "mission",
      custom: true,
      sortable: false,
    },
    {
      text: "Invoice ID",
      value: "id",
      custom: true,
    },
    {
      text: "Created On",
      value: "createdAt",
      custom: true,
    },
    {
      text: "Due Date",
      value: "dueAt",
      custom: true,
    },
    {
      text: "Amount",
      value: "total",
      sortable: false,
      custom: true,
    },
    {
      text: "Status",
      value: "state",
      sortable: false,
      custom: true,
    },
  ]

  statistics: { [key: string]: any } | null | undefined = null

  get queries() {
    return [
      {
        query: InvoicesListQuery,
      },
    ]
  }

  gotoDetailPage(invoice: ConsultantInvoice) {
    this.$router.push({
      name: this.$routes.InvoiceDetail,
      params: { id: invoice.id.toString() },
    })
  }

  onStatResult(result: FetchResult<FinanceStatisticsQueryQuery>) {
    this.statistics = result.data?.financeStatistics
  }
}
