var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('AppPage',{attrs:{"title":"Invoices"}},[_c('ApolloQuery',{attrs:{"query":_vm.statsQuery,"notifyOnNetworkStatusChange":""},on:{"result":_vm.onStatResult,"error":_vm.addGraphQLError},scopedSlots:_vm._u([{key:"default",fn:function({ result: { loading } }){return [(loading)?_c('v-row',{staticClass:"ma-0 mb-4"},_vm._l((3),function(n){return _c('v-col',{key:`loader_${n}`,attrs:{"cols":"12","sm":"4"}},[_c('v-skeleton-loader',{attrs:{"type":"list-item-two-line"}})],1)}),1):_c('v-row',{staticClass:"ma-0 mb-4"},_vm._l((_vm.statistics),function(stat,key){return _c('v-col',{key:key,staticClass:"pa-0 pr-2 pb-2",attrs:{"cols":"12","sm":"4"}},[_c('v-card',{staticClass:"px-5 py-5",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"p-0 d-block headline font-weight-bold mt-n1",staticStyle:{"word-break":"break-word"}},[_vm._v(_vm._s(stat.value))]),_c('v-card-subtitle',{staticClass:"p-0 text-subtitle-2 mt-0"},[_vm._v(" "+_vm._s(stat.label)+" ")])],1)],1)}),1)]}}])}),_c('Table',{ref:"table",staticClass:"row-clickable mb-5",attrs:{"query":_vm.queries[0].query,"queryKey":"consultantInvoices","headers":_vm.headers,"exportOptions":{
      headers: [
        {
          text: 'Consultant',
          value: 'consultant.name',
        },
        {
          text: 'Invoice ID',
          value: 'id',
        },
        {
          text: 'Created On',
          value: 'createdAt',
        },
        {
          text: 'Due Date',
          value: 'dueAt',
        },
        {
          text: 'Amount',
          value: 'total',
        },
        {
          text: 'Status',
          value: 'state',
        },
      ],
    },"listFilters":[
      {
        field: 'state',
        label: 'State',
        filters: {
          PENDING: 'Pending',
          APPROVING: 'To approve',
          APPROVED: 'Approved',
          QUEUED: 'Queued for payment',
          PAID: 'Paid',
        },
      },
      {
        field: 'missionIdIn',
        label: 'Mission',
        queryFilter: {
          query: _vm.missionLifecycleSelectQuery,
          itemValue: 'id',
          variables: {
            filter: { stateIn: ['execution_stage', 'paused', 'completed'] },
          },
          listKey: 'missionLifecycles',
          per: 500,
          returnObject: true,
        },
        icon: 'la-rocket',
      },
    ],"dateFilters":[
      {
        field: 'dueAtDate',
        label: 'Due At',
      },
    ],"searchFilters":[
      {
        field: 'id',
        label: 'Invoice ID',
        type: 'numeric',
      },
      {
        icon: 'la-user-tag',
        field: 'search',
        label: 'Consultant name',
      },
    ]},on:{"click:row":_vm.gotoDetailPage},scopedSlots:_vm._u([{key:`item.consultant`,fn:function({ item }){return [_c('div',[_vm._v(" "+_vm._s(item.consultant.name)+" ")])]}},{key:`item.mission`,fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(item.missionLifecycle.name)+" ")])]}},{key:`item.id`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s("#" + item.id))])]}},{key:`item.state`,fn:function({ item }){return [_c('state-chip',{staticClass:"ml-0",staticStyle:{"margin-left":"0 !important"},attrs:{"state":item.state.replaceAll('_', ' ').replace('stage', '')}})]}},{key:`item.dueAt`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.formatDate(_vm.$moment(item.dueAt), "Do MMM YYYY")))])]}},{key:`item.createdAt`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.formatDate(_vm.$moment(item.createdAt), "Do MMM YYYY")))])]}},{key:`item.total`,fn:function({ item }){return [_c('span',[_vm._v("USD "+_vm._s(_vm.moneyFormat(item.total)))])]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }